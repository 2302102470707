@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/spacing.scss';

.upskillingImage{
    height: rem(363.1px);
    width: 100%;

    @media screen and (max-width: $laptop) {
        height: rem(260px);
    }
    @media screen and (max-width: $tablet) {
        height: rem(230px);
    }
}

.upskillingWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:flex-start;
    height: rem(540px);

    @media screen and (max-width: $laptop) {
        height: rem(450px);
    }
    @media screen and (max-width: $tablet) {
        height: fit-content;
    }

    .title{
        margin: rem(-7px) rem(70px) rem(20px) 0;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: colors.$primaryBlack;
       
        @media screen and (max-width: $tablet) {
            font-size: 28px;
        }
    }
}