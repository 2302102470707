@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';


@-webkit-keyframes fadeOut {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; 
        // transform: rotate(1turn); 
        transform: rotate(0.5turn);  
  
     }
    to { opacity: 1;
       }
}
@keyframes fadeOut {
    from { opacity: 0; 
     }
    to { opacity: 1;
        transform: rotate(0.5turn);   

       }
}

.openMenuBar, .closeMenuBar{ 
    height: rem(36px);
    width: rem(36px);
    cursor: pointer; 
    color: colors.$primaryWhite;
 
    @media screen and (min-width: $laptop) {
        display: none; 
    }
}

.openMenuBar {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    
}

.closeMenuBar {
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
    
}

.leftShape{
    width: 40vw;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: $tablet) {
      width: 90vw;
    }
}


.expandedMenu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: transparent ;
    transition: all 1s ease-out;  
    height: 90vh ;
    width: 100%;
    border-bottom-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
  
    @media screen and (max-height: rem(579px)) {
        overflow: auto;
    }
    @media screen and (min-width: $tablet) {
        overflow: auto;
       }
    @media screen and (min-width: $laptop) {
        display: none;
        
    }
}


.menuMobile{
    width: 100%;
    padding: rem(15px);
    align-items: flex-start;
    color: colors.$primaryWhite;
    font-size: typography.$fontSize-regular; 
  
    .listBtn{
        border-top: 1px solid rgba(255,255,255,0.2);

        &:first-child{
            margin-top: rem(25px);
            border-top: none
        }
   
        &:hover{
            color: colors.$primaryBlue ;
        }

        &:focus{
            color: colors.$primaryBlue ;
        }
  }

.listSubMenuBtn{
    padding: rem(3px) rem(15px);
    
    &:last-child{
        margin-bottom: rem(12px);
    }
    .subMenuBtn{
        margin: 0px;
        
        span{
        padding-left: rem(15px);
        font-size: typography.$fontSize-small; 
        }
    }
  }
}

.mobileBtnGroup{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem(40);

    a{
        margin-bottom: rem(24px);
        color: colors.$primaryWhite !important;

        &:hover{
            color: colors.$primaryBlue !important;
        }
    }
    
    @media screen and (max-width: $tablet) {
        align-items: stretch;
        text-align: center;
        width: 100%;
    }
}

.btnGroup > * {
    margin-left: rem(24px);
    @media screen and (max-width: $laptop) {
        display: none;

    }
}

.logoName{
    display: flex;
    align-items: center;
}