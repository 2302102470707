@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/spacing.scss';

.bannerContainer{
    width: 100%;
    height: fit-content ;
    background-color: colors.$primaryBlue;
    border-radius: 0px 0px rem(80px) 0px;
    position: relative;
    padding: rem(40px) rem(100px) rem(40px) rem(100px);
    display: flex;
    align-items:center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: rem(60px);
    
    @media screen and (max-width: $tablet) {
        padding: rem(40px) rem(25px) rem(40px) rem(32px);

    }

    /*
        Corner shapes
    */
    .bannerShape{
        position: absolute;
        
        path { 
            opacity: 0.1;
            fill: colors.$primaryWhite;   
        }

        &:nth-child(1) { // The left corner shape for the call to action banner will be placed in top left
            height: rem(188px);
            width: rem(105px);
            top: 0;
            left: 0;
        }

        &:nth-child(2) { // The right corner shape for the call to action banner will be placed in bottom right
            height: rem(100px);
            width: rem(100px);
            border-radius: 0px 0px rem(80px) 0px;
            right: 0;
            bottom: 0;
            }
    }

    h6{
        color: colors.$primaryWhite;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        width: 60%;

        @media screen and (max-width: $tablet) {
            width: 100%;
            margin-bottom: rem(30px);
        }
    }
}



