@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';

.contactAvatarPlaceholder {
    height: rem(88px);
    width: rem(88px);
    border-radius: rem(24px);
    overflow: hidden;
    background-color: colors.$primaryBlue;
    display: flex;
    justify-content: center;
    align-items: center;
}