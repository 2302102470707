@use 'colors' as *;
@use 'typography';
@use 'functions';
@use 'spacing';

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root{
  --turquoise-blue: #{$primaryBlue};
  --turquoise-blue-hover: #{$btnMainHover};
  --ice-blue: #{$primaryLightBlue};
}

html{
  scroll-behavior: smooth;
}