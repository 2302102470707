@use '../../../styles/typography.scss';
@use '../../../styles/colors.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/functions.scss';

.menuTitle{
    margin: rem(14px) 0 rem(16px) 0;
    text-transform: uppercase;
    font-size: typography.$fontSize-small ;
    color: colors.$primaryBlue;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: rem(1.38px);
    letter-spacing: rem(1.28px);
}

.footerText{
    width: rem(450px);
    font-size: typography.$fontSize-small ;
    color: colors.$primaryDarkGrey;
    margin: rem(24px) 0 rem(24px) rem(0.5px);
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    @media screen and (max-width: $laptop) {
        width: 100%;
    }

    @media screen and (max-width: $tablet) {
        margin-bottom: rem(0px);
    }

}

.footerLink{
    font-size: typography.$fontSize-small ;
    color: colors.$primaryBlue;
    margin: 0 0 0 rem(0.5px);
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}


.footerDivider{
    margin: rem(31px) 0 rem(11px) 0;
    border: rem(1px) solid colors.$primaryLightGrey;
    @media screen and (max-width: $tablet) {
        display: none;
    }
}

.copyRight {
    margin: 0 0 0 0;
    font-size: typography.$fontSize-small ;
    color: colors.$primaryDarkGrey;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    @media screen and (max-width: $tablet) {
       margin: 0 auto;
     }
}

.privacy{
    display: flex;
    margin-left: auto;
   
    @media screen and (max-width: $mobile) {
       display: none;
    }
}