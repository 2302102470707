@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';


.VersatileImage{
    height: rem(314.6px);
    width: 100%;

    @media screen and (max-width: $laptop) {
        height: rem(260px);
    }
    @media screen and (max-width: $tablet) {
        height: rem(230px);
    }
}

.versatileWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:flex-start;
    height: rem(540px);

    @media screen and (max-width: $laptop) {
        height: fit-content;
    }
       
    .title{
        margin: rem(4px) 0 rem(15px) 0;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: colors.$primaryBlack;

        @media screen and (max-width: $tablet) {
            font-size: 32px;
        }
    }

    .subTitle{
        margin-top:  0;
        margin-bottom:  rem(15px);
        font-size: typography.$fontSize-18;
        color: colors.$primaryDarkGrey;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        
        @media only screen and (max-width: $laptop) and (min-width: $mobile)  { 
            margin-bottom:  0;
        }
    }
}