@use "../../../../styles/colors.scss";
@use "../../../../styles/spacing.scss" as *;
@import "../../../../styles/functions.scss";


.textContainer {
  .title {
    margin-top: rem(24px);
    font-weight: normal;
  }
  .text {
    margin: rem(24px) 0 0 0;
    color: colors.$primaryDarkGrey;
  }
}

.contactContainer {
  margin-top: rem(40px);
}
