@use "../../../../styles/colors.scss";
@use "../../../../styles/spacing.scss" as *;
@import "../../../../styles/functions.scss";

.contactInformationContainer {
  display: flex;
  align-items: center;
  margin-bottom: rem(24px);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.textContentWrapper {
  margin-left: rem(24px);
  
  & p {
    margin: 0;
    line-height: 1.33;
    font-family: 'Graphik';
  }

  & a {
    display: block;
    text-decoration: none;
    color: colors.$primaryDarkGrey;
  }
}