/* Main Colors */
$primaryBlue: #00A2D2;
$primaryBlack: #37353B;
$primaryDarkGrey: #4F4D57;
$primaryLightGrey: #D1D1D1;
$primaryLightBlue: #E5F6FB;
$primaryOffWhite: #F3F4F6;
$primaryWhite: #FFFFFF;

$logoMainColor: #249eb6;
$logoBlack: #000000;

/* Gradient Colors */
$gradientBlack: transparent linear-gradient(92deg, #101010 0%, #2F2F2F 100%) 0% 0% no-repeat padding-box;

/* Button Colors */
$btnMainHover: #00B1E6;
$btnInvertHover: #D2F5FF;
$btnSmallHover: #C4EAF5;

:export {
    primaryBlue: $primaryBlue;
    primaryLightGrey: $primaryLightGrey;
}