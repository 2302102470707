@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.cardWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    max-width: rem(350px);
    height: rem(240px);

    @media screen and (max-width: $tablet) {
        height: fit-content;
    }

    @media only screen and (max-width: $laptop) and (min-width: $tablet)  {
        padding: 0 rem(5px);
        height: fit-content;
    }

    .userComment{
        margin: 0 0 rem(4px);
        color: colors.$primaryDarkGrey;
        font-size: typography.$fontSize-18;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
    }

    .userData{
        display: flex;
        align-items: center;
        @media screen and (max-width: $tablet) {
            margin-top: rem(15px);   
        }

        .userImage {
            border-radius: 50%;
            margin-right: rem(16px);

            img {
                width: rem(48px);
                height: rem(48px);
                border-radius: inherit;
            }
        }

        .userInfo{
            p{
                margin-top: 0;
                margin-bottom: rem(2px);
                font-size: typography.$fontSize-xsmall;
                color: colors.$primaryBlue;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.4px;
                text-align: left;
                text-transform: uppercase;
                @media screen and (max-width: $tablet) {
                    font-size: rem(13px);           
                }
            }
            
            span{
                font-size: typography.$fontSize-xsmall;
                color: colors.$primaryDarkGrey;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                @media screen and (max-width: $tablet) {
                    font-size: rem(13px);            
                }
            }
        }
    }
}

.title{
    margin-bottom: rem(24px);
 }

 .subTitle{
     margin-bottom: rem(48px);
     color: colors.$primaryBlack;
     font-stretch: normal;
     font-style: normal;
     letter-spacing: normal;
     text-align: center;

     @media screen and (max-width: $tablet) {
         height: rem(32px);
     }
 }

 .carouselWrapper{
    margin-bottom: rem(50px);
 }
