@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.wrapper{
    width: 100%;

    .item{
      box-shadow: none;
      background-color: transparent;

      &::before{
        display: none;
      }
    }

    .summary{
      padding-left: 0;
      padding-right: 0;

      &.active{
        svg{
            color: colors.$primaryBlue;
        }
      }
    }

    &.variant1{
      .item{
        & .summary_title{
            color: colors.$primaryLightGrey;
            font-family: 'Graphik';
            line-height: normal;
            border-bottom: rem(3px) solid  colors.$primaryLightGrey;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            padding-bottom: rem(15px);

            @media screen and (max-width: $tablet) {
              font-size: rem(22px);
            }
            
            &.active{
                color: colors.$primaryBlue;
                border-bottom: rem(3px) solid colors.$primaryBlue;
            }
        }

        .simple_text{
          font-size: typography.$fontSize-18;
          color: colors.$primaryDarkGrey;
          font-family: Roboto;
          line-height: 1.5;
          letter-spacing: normal;
          font-stretch: normal;
          font-style: normal;
          font-weight: 400;
          
          @media screen and (max-width: $tablet) {
            font-size: typography.$fontSize-small;
          }
        }
      }
    }

    &.variant2{
      .item{
        border-bottom: 1px solid #dddddd;

        &:last-of-type{
          border: none;
        }

        .summary_title{
          color: colors.$primaryDarkGrey;
          font-family: 'Graphik';
          font-size: rem(20px);
          line-height: rem(26px);
          letter-spacing: 0;

          &.active{
              color: colors.$primaryBlue;
          }
        }

        .simple_text{
          letter-spacing: 0;
          line-height: rem(27px);
        }
      }
    }

    &.variant3{
      margin-top: rem(31px) ;
      .item{
        border-bottom: 1px solid #dddddd;

        &:first-of-type{
          border-top: 1px solid #dddddd;
          border-radius: 0px;
        }

        &:last-of-type{
          border-radius: 0px;
        }

        .summary_title{
          color: colors.$primaryBlue;
          font-family: 'Graphik';
          font-size: rem(16px);
          line-height: rem(22px);
          letter-spacing: rem(1.28px);
        }

        .link_text{
          display: block;
          text-decoration: none;
          color: colors.$primaryDarkGrey;
          font-size: rem(16px);
          line-height: rem(24px);
          letter-spacing: 0;
        }
      }

      @media screen and (min-width: $tablet) {
       display: none;
      }
  }
}