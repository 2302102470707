@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';
@font-face {
  font-family: 'Graphik';
  src: url('../../../assets/fonts/GraphikMedium.otf');
}

.button {
  background-color: colors.$primaryBlue;
  border-radius: rem(8px);
  border: rem(2px) solid colors.$primaryBlue;
  padding: 0 rem(32px);

  p {
    color: colors.$primaryWhite;
    text-transform: capitalize;
    font-size: typography.$fontSize-18;
  }

  &:hover {
    background-color: colors.$btnMainHover;
  }

  &.inverted {
    background-color: colors.$primaryWhite;
    border: rem(2px) solid colors.$primaryWhite;

    p {
      color: colors.$primaryBlue;
    }

    &:hover {
      background-color: colors.$btnInvertHover;
  
      p {
        color: colors.$primaryBlue;
      }
    }
  }

  &.medium {
    padding: rem(10px);

    p {
      font-size: typography.$fontSize-small;
      margin: 0;
    }
  }
}

.outlined {
  background-color: transparent;
  border: rem(2px) solid colors.$primaryBlue;
  p {
    color: colors.$primaryBlue;

  }

  &:hover {
    background-color: colors.$primaryBlue;
    border: rem(2px) solid colors.$primaryBlue;
    p {
      color: colors.$primaryWhite;

    }
  }

  &.inverted {
    background-color: transparent;
    border: rem(2px) solid colors.$primaryWhite;

    p {
      color: colors.$primaryOffWhite;
    }

    &:hover {
      background-color: colors.$btnInvertHover;
      border: rem(2px) solid colors.$btnInvertHover;
  
      p {
        color: colors.$primaryBlue;
      }
    }
  }
}

.outlinedWhite {
  background-color: transparent;
  border: rem(2px) solid colors.$primaryWhite;
  
  p {
    color: colors.$primaryWhite;

  }

  &:hover {
    background-color: colors.$btnInvertHover;
      border: rem(2px) solid colors.$btnInvertHover;
  
    p {
      color: colors.$primaryBlue;

    }
  }

 
}

.btnFullWidth{
  margin-bottom: rem(15px);
  @media screen and (max-width: $tablet) {
    width: 100%;
    margin-bottom: rem(15px);
  }
  
}

.small {
  background-color: colors.$primaryLightBlue;
  border-radius: rem(8px);
  border: rem(2px) solid colors.$primaryLightBlue;
  padding: rem(6px) rem(20px);

  p {
    font-family: 'Graphik', 'Roboto', 'Open Sans', sans-serif;
    color: colors.$primaryBlue;
    text-transform: capitalize;
    font-size: typography.$fontSize-small;
    margin: 0;
  }

  &:hover {
    background-color: colors.$btnSmallHover;
    border: rem(2px) solid colors.$btnSmallHover;
  }
}

.contactUsButton {
  width: 164px;
  border: none;
  margin-top: rem(24px);

  p {
    line-height: 1.167;
  }

  &:hover {
    border: none;
  }
}