@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.base {
  display: flex;
  flex-direction: column;
  height: min-content;
  justify-content: left;
  row-gap: $containerRowGap;
  @media screen and (min-width: $tablet) {
    gap: 0;
  }
}
.full {
  width: 100%
}

.twoThird {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 62%;
  }
}

.half {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 48%;
  }
}
.halfLarge {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 50%;
  }
}
.halfSmall {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 46%;
  }
}

.halfRowDirection {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 48%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.third {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 32%;
  }
}

.quart {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 32%;
  }
  @media screen and (min-width: $laptop) {
    width: 24%;
  }
}

.fifth {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 24%;
  }
  @media screen and (min-width: $laptop) {
    width: 19%;
  }
}

.displayNoneMobile{
  @media screen and (max-width: $tablet) {
    display: none;
  }
}