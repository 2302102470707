@use '../../../styles/spacing.scss' as *;

.item{

  @media screen and (min-width: $tablet) { 
    width: calc(100% - rem(30px));
  }
}

.image{
  aspect-ratio: 35/26;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 260px;
}

.chip{
  margin-top: rem(24px);
}

.title{
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}