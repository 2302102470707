@use '../../styles/typography.scss' as *;

.contactUsLeft {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 45%;
  }
}

.contactUsRight {
  width: 100%;
  margin-top: rem(40px);
  @media screen and (min-width: $tablet) {
    margin-top: initial;
    width: 50%;
  }
}

.contactUsActionBanner {
  margin: rem(82px) 0 rem(-45px) 0;
  @media screen and (max-width: $tablet) {
    flex-flow: column-reverse;
  }
}