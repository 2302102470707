@use '../../../styles/typography.scss';
@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.nav > * {
    margin-right: rem(24px);
}

.nav{
    display: flex;
    justify-content: center;
    align-items: center;


    @media screen and (max-width: $laptop) {
        display: none;
        flex-direction: column;
        justify-content: left;
        align-items: left !important;
    }
}

.vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
   
    a{
        justify-content: left;
    }
}

.text-white {
    font-size: typography.$fontSize-small;
    text-transform: capitalize;
    color: colors.$primaryWhite;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    span {
        margin-left: rem(4px);
    }
}

.text-black {
    font-size: typography.$fontSize-small;
    text-transform: capitalize;
    color: colors.$primaryBlack;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    span {
        margin-left: rem(4px);
    }
}

.menuPaper {
    border: rem(1px) solid colors.$primaryLightGrey;
    border-radius: 0;
    min-width: rem(160px);
    margin-top: rem(34px);
}

.menuitem {
    color: colors.$primaryBlack;
    text-transform: capitalize;

    &:hover {
        color: colors.$primaryBlue;
        background-color: transparent;
    }
}