@import 'functions';

/* Grid definitions */
$gridColumnWidth: rem(103px);
$gridRowGap: rem(25px);
$containerRowGap: rem(10px);

/* Basic (section) padding*/
$basePadding: rem(24px);

/* Screen Sizes */
$mobile: rem(320px);
$tablet: rem(768px);
$laptop: rem(1024px);
$desktop: rem(1280px);
$desktopXL: rem(1281px);