@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';


.carousel_wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
