@use 'colors';
@import 'functions';
@import 'spacing';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/GraphikMedium.otf');
}

$fontSize-regular: rem(18px);
$fontSize-small: rem(16px);
$fontSize-xsmall: rem(14px);

/* Main fonts */
$fontSize-18: rem(18px);

h1, h2, h3, h4, h5, h6 {
    font-family: 'Graphik', 'Roboto', 'Open Sans', sans-serif;
    color: colors.$primaryBlack;
    font-weight: 500;
    margin: 0;
}

h1 {
    font-size: rem(40px);
    line-height: 1.2;

    @media screen and (min-width: $laptop) {
        font-size: rem(72px);
        line-height: 1.11;
    }
}

h2 {
    font-size: rem(32px);
    line-height: 1.25;

    @media screen and (min-width: $laptop) {
        font-size: rem(56px);
        line-height: 1.14;
    }
}

h3 {
    font-size: rem(24px);
    line-height: 1.33;

    @media screen and (min-width: $laptop) {
        font-size: rem(48px);
        line-height: 1.17;
    }
}

h4 {
    font-size: rem(20px);

    @media screen and (min-width: $laptop) {
        font-size: rem(40px);
        line-height: 1.2;
    }
}

h5 {
    font-size: rem(18px);

    @media screen and (min-width: $laptop) {
        font-size: rem(32px);
        line-height: 1.25;
    }
}

h6 {
    font-size: rem(24px);
    line-height: 1.33;
}

p {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: $fontSize-regular;
    line-height: 1.5;
}

:export {
    fontSize18: $fontSize-18;
}