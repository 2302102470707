@import "../../../styles/functions.scss";
@import "../../../styles/spacing.scss";

.base {
  display: flex;
  flex-flow: column wrap;
  height: min-content;
  justify-content: flex-start;
  padding: rem(20px) 0;
  gap: rem(10px);

  @media screen and (min-width: $tablet) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.center {
  justify-content: center;
}

.fellowSection-37padding {
  padding: rem(37px) 0;
  @media screen and (max-width: $tablet) {
    flex-flow: column-reverse;
  }
}

.fellowSection-displayNone {
  padding: rem(37px) 0;
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.mobile-version{
  @media screen and (max-width: $laptop) {
    position: relative;
    height: fit-content; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
}
