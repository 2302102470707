@use '../../../styles/spacing.scss' as *;

.section{
  
  .header{
    position: relative;
    width: 100%;
    margin-bottom: rem(32px);
    
    @media screen and (min-width: $tablet){
      margin-bottom: rem(64px);
    }

    .title{
      margin-top:rem(16px);
      font-weight: 500;

      @media screen and (min-width: $tablet){
        margin-top:rem(24px);
      }
    }
  }

  .carousel{
    margin-bottom: rem(30px);
    
    @media screen and (min-width: $tablet) {
      margin-bottom: rem(112.2px);
    }
  }
  
  .button{
    padding: rem(22px) rem(32px) rem(21px);
    border-radius: rem(8px);
    color: white;
    font-size: rem(18px);
    font-weight: 500;
    text-decoration: none;
    width: fit-content;
    white-space: nowrap;
    margin-left: 50%;
    transform:translateX(-50%);
    background-color: var(--turquoise-blue);
    display: inline-block;
    
    &.tablet{
      display: none;
    } 
    
    &:hover{
      background-color: var(--turquoise-blue-hover);
    }
    
    
    
    @media screen and (min-width: $tablet) {
      &.mobile{
        display: none;
      }
      
      &.tablet{
        display: inline-block;
      }
      
      position: absolute;
      line-height: 1;
      right: 0;
      bottom: 0;
      margin-left: initial;
      transform:translateX(0)
    }
  }
}