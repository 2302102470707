@use "../../../../styles/colors.scss";
@use "../../../../styles/spacing.scss" as *;
@use "../../../../styles/typography.scss";
@import "../../../../styles/functions.scss";

.formBox {
  background-color: colors.$primaryOffWhite;
  padding: rem(56px) rem(32px);
  border-radius: 0px 0px 80px 0px;
  display: flex;
  flex-direction: column;
  color: colors.$primaryDarkGrey;

  & > div {
    width: 100%;
    margin-bottom: rem(8px);
    padding: 0;

    & fieldset {
      border-color: colors.$primaryLightGrey;
    }

    & input {
      height: rem(29px);
      padding-left: rem(16px);
      padding-right: rem(16px);
      color: colors.$primaryDarkGrey;
    }

    & input[type="number"]::-webkit-outer-spin-button,
    & input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    & input[type="number"] {
      -moz-appearance: textfield;
    }

    & input::placeholder {
      color: colors.$primaryDarkGrey;
    }

    & textarea::placeholder {
      color: colors.$primaryDarkGrey;
    }
  }

  & > div:last-of-type {
    margin-bottom: rem(0px);
  }

  .formText {
    margin-top: 0;
    margin-bottom: rem(24px);
  }

  .textArea {
    margin-bottom: rem(26px);

    & div {
      padding: 0;

      & textarea {
        padding: rem(16.5px) rem(16px);
        color: colors.$primaryDarkGrey;
      }
    }
  }
}

.formControl {
  align-items: flex-start;
  margin: 0 0 rem(24px) 0;
  max-width: rem(380px);

  span {
    padding: 0;
  }

  .formCheckbox {
    margin-right: rem(16px);
  }

  .checkboxIcon {
    width: 24px;
    height: 24px;
    border: 1px solid colors.$primaryLightGrey;
    border-radius: 4px;
  }

  .checkboxChecked {
    background-color: colors.$primaryBlue;
    border-color: colors.$primaryBlue;
    display: flex;
    justify-content: center;
    & svg {
      fill: colors.$primaryWhite;
      width: 15px;
    }
  }
}

.button {
  background-color: colors.$primaryBlue;
  border-radius: rem(8px);
  padding: rem(10px);
  border: none;
  width: 164px;
  height: 64px;
  margin-top: rem(24px);
  color: colors.$primaryWhite;
  text-transform: none;
  line-height: 1.167;
  font-size: typography.$fontSize-18;

  &:hover {
    background-color: colors.$btnMainHover;
    border: none;
  }
}
