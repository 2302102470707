@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';
@mixin base {
    fill-opacity: 1;
    fill-rule: nonzero;
    stroke: none;
    stroke-width: 1.33333;
    align-items: center;
}

.full {
    height: rem(22px);
    width: rem(130px);
}

.defaultColor {
    fill: colors.$logoMainColor;
}

.white {
    @include base;
    fill: colors.$primaryWhite;
}

.black {
    @include base;
    color: colors.$logoBlack;
}