@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';

.subheading{
  	font-family: 'Graphik', 'Roboto', 'Open Sans', sans-serif;;
  	font-weight: 500;
	margin-block-start: rem(0px);
	margin-block-end: rem(0px);

  	&.small{
      	font-size: rem(16px);
      	color: colors.$primaryBlue;
      	line-height: 1.38;
      	letter-spacing: rem(1.28px);
      	text-transform: uppercase;
  }
  
  	&.medium{
	font-size: rem(20px);
	color: colors.$primaryBlack;
}
}