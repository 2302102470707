@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/functions.scss';

.fellowshipSectionHeading ,.becomeFellowSection{
    .sectionTitle{
        font-size: typography.$fontSize-small;
        color: colors.$primaryBlue;
        letter-spacing: rem(1.28px);
        text-transform: uppercase;
        text-align: left;
        margin-bottom: rem(24px);
    }

    .subTitle{
        letter-spacing: rem(0px);
        text-align: left;
        color: colors.$primaryWhite;
        @media screen and (max-width: $tablet) {
            font-size: rem(36px);
            line-height: rem(40px);
        }
    }
}


.fellowshipSectionLeftContainer{
    .quote{
        margin: rem(37px) 0 rem(12px) 0 ;
        color: colors.$primaryWhite;
        text-align: left;
    }

    .description{
        color: colors.$primaryLightGrey;
        font-size: typography.$fontSize-18;
        text-align: left;
        margin: rem(12px) 0;
    }

    .name{
        letter-spacing: rem(1.28px);
        font-size: typography.$fontSize-small;
        line-height: rem(22px);
        font-weight: 500;
        text-transform: uppercase;
        color: colors.$primaryBlue;
        margin: rem(12px) 0 rem(8px) 0;
    }

    .title{
        font-size: typography.$fontSize-regular;
        color: colors.$primaryLightGrey;
        line-height: rem(24px);
        font-weight: 500;
        margin: 0 0 rem(12px) 0;
    }
}

.becomeFellowSection{
    text-align: center;

    .sectionTitle{
        text-align: center;
        margin-bottom: rem(24px);
        font-family: Graphik;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 1.28px;
    }

    .sectionDescription{
        color: colors.$primaryWhite;
        text-align: center;
        margin-bottom: rem(32px);
        padding: 0 rem(95px);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;

        @media screen and (max-width: $tablet) {
            padding: 0;
        }
    }
}

.smallDivider{
    margin: rem(12px) 0 rem(12px) 0;
    width: 75%;
    opacity: 0.2;
    border: 1px solid #FFFFFF;

    @media screen and (max-width: $tablet) {
        width: 100%;
    }
}

.longDivider{
    margin: rem(12px) 0 rem(63px) 0;
    width: 100%;
    opacity: 0.2;
    border: 1px solid #FFFFFF;

    @media screen and (max-width: $tablet) {
        width: 100%;
        margin-bottom: rem(40px);
    }
}

.displayNone{
    @media screen and (max-width: $tablet) {
        display: none;
    }
}