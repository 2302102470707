@use '../../../styles/colors.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/functions.scss';

/*
 Main Frame Styling
 */
.mainPictureFrame{
    width: 100%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: colors.$primaryOffWhite;
}

.position-right{
    float:right;
}



/*
BackGround colors
 */
.primary-blue{
    background-color: colors.$primaryBlue;   
}

.transparent-ground{
    background-color: transparent;   
}
.blue-background{
    background-color: #E5F5FB;   
}

/*
Frames heights
 */
.frame-620height { 
    height: rem(620px);
}

.frame-580height { 
    height: rem(580px);
}

.frame-540height { 
    height: rem(540px);
 
    @media screen and (max-width: $laptop) {
        height: rem(450px);
    }
    @media screen and (max-width: $tablet) {
        width: 100%;
        height: rem(420px);
        margin: rem(50px) 0 rem(50px) 0 ; 
    }
    @media screen and (max-width: $mobile) {
        
        height: rem(392.7px);
    }
}

.frame-520height { 
    height: rem(520px);
    @media screen and (max-width: $tablet) {
        height: rem(320px);
    }
   
}

.frame-480height { 
    height: rem(480px);
}

.frame-460width{
    width: 85%;
    @media screen and (max-width: $tablet) {
        width: 100%;
    }
    @media screen and (max-width: $laptop) {
        width: 100%;
    }
}

.contactAvatarFrame { 
  height: rem(88px);
  width: rem(88px);
  border-radius: rem(24px);
}

/*
Images and shapes sizes 
 */
.img-cover{
  width: 100%;
  object-fit: cover;
}

.img-medium{
    width:( calc(100% / 1.3));
}

.img-small{
    width: calc(100% / 1.5 );
}

.img-extra-small{
    width: calc(100% / 3 );
}

.img-right-rounded{
    width: calc(100% - rem(40px));
    height: calc(100% - rem(40px));
    object-fit: contain;
    border-radius: 0px 0px rem(160px) 0px;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: $laptop) {
        border-radius: 0px 0px rem(120px) 0px;
    }
    @media screen and (max-width: $tablet) {
        border-radius: 0px 0px rem(90px) 0px;
    }
}

.img-left-rounded{
    width: calc(100% - rem(40px));
    height: calc(100% - rem(40px));
    object-fit: cover;
    border-radius: 0px 0px 0px rem(160px);
    position: absolute;
    bottom: 0;
    left: 0;
}

.contactAvatarImageFrame { 
  height: 100%;
  width: 100%;
  object-fit: cover;
} 

.accent-right-top-corner{
    z-index: 1;
    margin-top: 0;
    width: rem(213px);
    height: rem(213px);
    position: absolute;
    top:0;
    right: 0;
    transform: rotate(-90deg);
   
    path { 
        fill: #00949e 
    }

    @media screen and (max-width: $laptop) {
        width: rem(165px);
        height: rem(165px);
    }
    @media screen and (max-width: $tablet) {
        width: rem(145px);
        height: rem(145px);
    }
}

/* 
Border Radius Values 
*/
.border-rounded-right-160px{
    border-radius: 0px 0px rem(160px) 0px;

    @media screen and (max-width: $laptop) {
        border-radius: 0px 0px rem(120px) 0px;
    }
    @media screen and (max-width: $tablet) {
        border-radius: 0px 0px rem(90px) 0px;
    }
}

.border-rounded-left-160px{
    border-radius: 0px 0px 0px rem(160px);
    
    @media screen and (max-width: $laptop) {
        border-radius: 0px 0px 0px rem(120px);
    }
    @media screen and (max-width: $tablet) {
        border-radius: 0px 0px 0px rem(90px) ;    }
}