@use '../../../styles/typography.scss';
@use '../../../styles/colors.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.text-white {
    font-size: typography.$fontSize-small;
    text-transform: capitalize;
    color: colors.$primaryWhite;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    span {
        margin-left: rem(4px);
    }
}

.text-black {
    font-size: typography.$fontSize-small;
    text-transform: capitalize;
    color: colors.$primaryBlack;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    span {
        margin-left: rem(4px);
    }
}

.privacy-footer{
    padding: 0;
    margin: 0 0 0 rem(24px);
    font-size: typography.$fontSize-small ;
    color: colors.$primaryDarkGrey;
    text-transform:capitalize;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;


    &:first-child{
        margin-left: 0;
    }

    &:hover {
        background-color: transparent;
    }
    @media screen and (max-width: $tablet) {
        font-size: 14px ;
    }
}