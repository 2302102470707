@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.rec.rec-carousel-wrapper{  
    .rec-slider-container{
        margin: 0;
    }

    .rec-carousel-item *{
        transition: 0.3s all ease-in-out;
    }

    .rec-carousel-item-visible{
        .rec-item-wrapper{
            @media screen and (min-width: $tablet) {
                padding: 0px !important;
                transform: translateX(-15px);
                margin-right: 30px;
            }

            @media screen and (min-width: $laptop) {
                padding: 0px !important;
                margin-left: rem(15px);
                margin-right: 0;
                transform: translateX(-30px);
            }
        }
    }

    .rec-carousel-item-prev{
        *{
            opacity: 0 !important;
        }
    }

    .rec-pagination{
        .rec-dot{
            background-color: colors.$primaryLightGrey;
            box-shadow: none;

            &_active{
                background-color: #fff;
                box-shadow: 0 0 rem(1px) rem(3px) colors.$primaryBlue;
            }
        }
    }
}
