@use '../../../styles/colors.scss';
@use '../../../styles/typography.scss';
@import '../../../styles/functions.scss';

/*
    List item styles
*/
.listItem{
    padding: rem(10px) rem(0);
}

.zeroLeftPadding{
    padding-left: 0;
}

.mediumVerticalPadding{
    padding-top: rem(5px);
    padding-bottom: rem(5px);
}

.smallVerticalPadding{
    padding-top: 0;
    padding-bottom: 0
}

/*
    List background-color
*/

.listItem:nth-child(odd){
    background-color: colors.$primaryOffWhite;
}

.listItem.primaryBackground{
    background-color: colors.$primaryWhite;
}

/*
    List Icon Styles
*/
.iconContainer{
    min-width: fit-content;
}

.listIcon{
    color: colors.$primaryBlue;
    width: rem(21px);
    height: rem(21px);
}

.bulletIcon{
    color: colors.$primaryBlue;
    width: rem(8px);
    height: rem(8px);
}

/*
    List Text styles
*/
.listText span{
    font-size:typography.$fontSize-regular;
    color: colors.$primaryDarkGrey;
    text-align: left;
    letter-spacing: 0px;
    margin-left: rem(12px);
}

