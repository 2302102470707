@import '../../../styles/functions.scss';
@import '../../../styles/spacing.scss';

.pageGrid {
  display: grid;
  grid-template-columns: 1fr $mobile 1fr;
  grid-template-rows: minmax(min-content, max-content);
  row-gap: $gridRowGap;

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr $tablet 1fr;
  }
  @media screen and (min-width: $laptop) {
    grid-template-columns: 1fr $laptop 1fr;
  }
  @media screen and (min-width: $desktop) {
    grid-template-columns: 1fr $desktop 1fr;
  }
  @media screen and (min-width: $desktopXL) {
    grid-template-columns: 1fr $desktopXL 1fr;
  }
}