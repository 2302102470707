@use "../../../styles/colors.scss";
@use "../../../styles/typography.scss";
@import "../../../styles/functions.scss";
@import "../../../styles/spacing.scss";

.base {
  top: 0;
  color: white;
  width: 100%;
  height: rem(850px);
  background: colors.$gradientBlack;
  opacity: 1;
  grid-column: 1 / 4;
  grid-template-columns: 1fr $mobile 1fr;
  display: grid;
  justify-content: center;
  padding: 0;
  position: relative;
  margin-bottom: rem(200px);

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr $tablet 1fr;
    margin-bottom: rem(90px);
  }
  @media screen and (min-width: $laptop) {
    grid-template-columns: 1fr $laptop 1fr;
  }
  @media screen and (min-width: $desktop) {
    grid-template-columns: 1fr $desktop 1fr;
  }
  @media screen and (min-width: $desktopXL) {
    grid-template-columns: 1fr $desktopXL 1fr;
  }

  .leftShape {
    width: 40vw;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: $tablet) {
      width: 90vw;
    }
  }

  .rightShape {
    width: rem(157px);
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .homeTitle {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: colors.$primaryWhite;
    width: 90%;
    margin-top: rem(240px);
    margin-bottom: rem(24px);

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin-bottom: 0;
      margin-top: rem(136px);
    }
  }

  .homeSubTitle {
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: colors.$primaryLightGrey;
    width: 60%;
    margin-bottom: rem(31px);

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin-bottom: rem(10px);
      margin-top: 0;
    }
  }

  .btnGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;

    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }

  .homeImage {
    position: absolute;
    right: 0;
    width: 70vw;
    height: auto;
    bottom: rem(-100px);

    @media screen and (max-width: $desktop) {
      width: 75vw;
      bottom: rem(-70);
    }
    @media screen and (max-width: $tablet) {
      width: rem(700px);
      bottom: rem(-180);
    }
    @media screen and (max-width: $tablet) {
      width: rem(580px);
      bottom: rem(-100);
    }
  }
  .headerTextContainer {
    display: block;
    width: 100%;
    justify-content: center;

    h4 {
      margin-top: rem(144px);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: colors.$primaryWhite;
    }
  }
}

.small {
  height: rem(240px);
}

.medium {
  height: rem(785px);
}

.contactUsPage {
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr $tablet 1fr;
    margin-bottom: rem(92px);
  }
  .headerTextContainer {
    h4 {
      margin-top: rem(124px);
    }
  }
}
