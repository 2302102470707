@use "../../../styles/colors.scss";
@import "../../../styles/functions.scss";
@import "../../../styles/spacing.scss";

.fullbleed {
  width: 100%;
  grid-column: 1 / 4;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: 1fr $mobile 1fr;
  display: grid;
  background: colors.$gradientBlack;
  justify-content: center;

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr $tablet 1fr;
  }
  @media screen and (min-width: $laptop) {
    grid-template-columns: 1fr $laptop 1fr;
  }
  @media screen and (min-width: $desktop) {
    grid-template-columns: 1fr $desktop 1fr;
  }
  @media screen and (min-width: $desktopXL) {
    grid-template-columns: 1fr $desktopXL 1fr;
  }
}

.content {
  display: grid;
  max-width: $desktopXL;
  grid-column: 2 / 3;
}

.margin40 {
  margin-top: rem(30px) ;
  margin-bottom: rem(30px) ;

  @media screen and (max-width: $tablet) {
    margin: 0;
  }
}
.padding136 {
  padding-top: rem(136px);
  padding-bottom: rem(136px);
}

.sticky,.mobileVersionSticky̦{
  position: fixed;
  z-index: 10;
  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr 90% 1fr;
  }

}
.sticky{
  background: transparent;  
}

.mobileVersionSticky̦{
  background: colors.$gradientBlack;
}

.gradientBlack {
  background: colors.$gradientBlack;
}

.contactUsWhite {
  background: colors.$primaryWhite;
}
